import { BrowserRouter } from 'react-router-dom';
import Root from './Root';
import noop from 'lodash/noop';
import { Security } from '@okta/okta-react';
import { oktaAuth } from './okta';

function App() {
  return (
    <BrowserRouter>
      {/* we're handling restoring original URI ourselves */}
      <Security oktaAuth={oktaAuth} restoreOriginalUri={noop}>
        <Root />
      </Security>
    </BrowserRouter>
  );
}

export default App;
