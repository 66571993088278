import {
  OktaAuth,
  OktaAuthOptions,
  SignoutOptions,
  AuthApiError,
} from '@okta/okta-auth-js';
import { isPrd, isCom } from './config';

export const OKTA_CONFIG: OktaAuthOptions = {
  issuer: isPrd
    ? 'https://apps.motional.com/oauth2/aus71kv8xktOUzCOP4x7'
    : isCom
    ? 'https://apps.motional.com/oauth2/ausdo1ds94iarNz4F4x7'
    : 'https://motional.oktapreview.com/oauth2/aus3k64vnhcspKf3r1d7',
  clientId: isPrd
    ? '0oa71l0qjr9dmPn1v4x7'
    : isCom
    ? '0oado1gorqubzyoiA4x7'
    : '0oa3k66s07vz97uqG1d7',
  scopes: ['openid', 'email', 'profile', 'offline_access'],
  redirectUri: `${window.location.origin}/callback`,
  tokenManager: {
    autoRenew: true,
  },
};

const SIGNOUT_OPTIONS: SignoutOptions = {
  postLogoutRedirectUri: `${window.location.origin}/login`,
};

export const oktaLogout = async () => {
  try {
    await oktaAuth?.signOut(SIGNOUT_OPTIONS);
  } catch (_error) {
    const error = _error as AuthApiError;
    alert(
      `Attempt to log out failed with the following error:\n${error.name} — ${
        error.message ??
        (error.xhr as Error | undefined)?.message ??
        'something went wrong'
      }\n\nPlease wait a minute as you may still be logged out. If not, contact support.`,
    );
  }
};

export const oktaAuth = new OktaAuth(OKTA_CONFIG);
