import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import yaml from 'js-yaml';
import { useCallback, useEffect, useState } from 'react';
import { env } from './config';
import SnackbarWrapper from './SnackBarWrapper';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import './SwaggerPage.scss';

type SwaggerSpec = ConstructorParameters<typeof SwaggerUI>[0]['spec'];

interface Props {
  title: string;
  token: string;
}

export default function SwaggerPage({ title, token }: Props) {
  const [isUnavailable, setIsUnavailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [spec, setSpec] = useState<SwaggerSpec>();

  // Construct the swagger url with the correct service title and env
  const getSwaggerUrl = (title: string) => {
    return `https://${title}.${env}.motional.cc/swagger`;
  };

  const checkAvailability = useCallback(async () => {
    const swaggerUrl = getSwaggerUrl(title);
    try {
      const response = await fetch(swaggerUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const data = await response.text();
        try {
          const doc = yaml.load(data) as SwaggerSpec;
          setSpec(doc);
        } catch (e) {
          setIsUnavailable(true);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsUnavailable(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsUnavailable(true);
      setIsLoading(false);
    }
  }, [title, token]);

  useEffect(() => {
    setIsUnavailable(false);
    setIsLoading(true);
    checkAvailability();
  }, [checkAvailability]);

  if (isUnavailable) {
    return (
      <SnackbarWrapper
        message={`Sorry, the ${title} service of the current environment is unavailable`}
        variant="info"
      />
    );
  } else {
    return (
      <>
        {!isLoading && spec && (
          <SwaggerUI
            spec={spec}
            onComplete={(swaggerUi) => {
              if (!token) return;

              swaggerUi.preauthorizeApiKey('BearerAuth', token);
              swaggerUi.preauthorizeApiKey('ApiAuth', token);
            }}
            showMutatedRequest={false}
          />
        )}

        <Fade in={isLoading} unmountOnExit>
          <CircularProgress />
        </Fade>
      </>
    );
  }
}
