import background from './media/motional-car-background.jpg';
import logo from './media/motional-logo_stack_color_dark-theme.svg';
import { useOktaAuth } from '@okta/okta-react';
import './Login.scss';

export default function Login() {
  const { oktaAuth } = useOktaAuth();

  const handleLogin = () => {
    oktaAuth.signInWithRedirect();
  };

  return (
    <div className="container">
      <img className="background" src={background} alt="" />
      <div className="background-color" />
      <div className="login-content">
        <div className="logo">
          <div>
            <img src={logo} alt="Motional" />
            <div className="title">API Documentation</div>
          </div>
        </div>
        <div className="bottom">
          <div className="login">
            <button type="button" onClick={handleLogin}>
              Login
            </button>
          </div>
        </div>
        <div className="copyright">
          Copyright © 2022 Motional | All Rights Reserved
        </div>
      </div>
    </div>
  );
}
