import { useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import SnackbarWrapper from './SnackBarWrapper';
import SwaggerPage from './SwaggerPage';
import useScopes from './useScopes';
import { useOktaAuth } from '@okta/okta-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Home.scss';

export default function Home() {
  const [selectedTitle, setSelectedTitle] = useState('');
  const [ifOpenSideBar, setIfOpenSideBar] = useState(false);
  const [isLargeWindow, setIsLargeWindow] = useState(true);
  const { scopes, haveScopesLoaded } = useScopes();
  const { authState } = useOktaAuth();
  const currentTitle = selectedTitle || scopes[0];

  const toggleSidebar = () => {
    setIfOpenSideBar(!ifOpenSideBar);
  };

  const onTitleChange = (newTitle: string) => {
    setSelectedTitle(newTitle);

    // Close the open sidebar if user is viewing on small window
    if (!isLargeWindow && ifOpenSideBar) {
      setIfOpenSideBar(false);
    }
  };

  // Get called when the window is rendered for first time
  useEffect(function resizeWindow() {
    const updateWindowDimensions = () => {
      const isCurrentLargeWindow = window.innerWidth >= 680;
      setIsLargeWindow(isCurrentLargeWindow);
    };

    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return function cleanup() {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  return (
    <div className="docs-layout">
      <Header
        className="docs-layout__header"
        toggleSidebar={toggleSidebar}
        isLargeWindow={isLargeWindow}
      />

      <main className="docs-layout__content">
        {!haveScopesLoaded ? (
          <CircularProgress />
        ) : currentTitle && authState?.accessToken ? (
          <SwaggerPage
            title={currentTitle}
            token={authState.accessToken.accessToken}
          />
        ) : (
          <SnackbarWrapper
            message="Sorry, there are no API documents available"
            variant="info"
          />
        )}
      </main>

      {currentTitle && (
        <Sidebar
          className="docs-layout__navigation"
          titles={scopes}
          onTitleChange={onTitleChange}
          open={ifOpenSideBar}
          isLargeWindow={isLargeWindow}
        />
      )}
    </div>
  );
}
