import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';

export default function Root() {
  const { authState } = useOktaAuth();

  if (authState?.isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/callback" element={<LoginCallback />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}
