import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useCallback, useState } from 'react';
import './Sidebar.scss';

interface Props {
  titles: string[];
  onTitleChange: (arg0: string) => void;
  open: boolean;
  isLargeWindow: boolean;
  className?: string;
}

export default function Sidebar(props: Props) {
  const { onTitleChange, className } = props;
  const [currentTitle, setCurrentTitle] = useState(props.titles[0]);

  const handleOnClick = useCallback(
    (title: string) => {
      return () => {
        onTitleChange(title);
        setCurrentTitle(title);
      };
    },
    [onTitleChange],
  );

  const removeHyphen = useCallback((title: string) => {
    return title.replace('-', ' ');
  }, []);

  return (
    <div
      className={`main-navigation ${
        props.isLargeWindow || props.open ? 'main-navigation--open' : ''
      } ${className || ''}`}
    >
      {props.titles.map((title) => (
        <div
          key={title}
          className={currentTitle === title ? 'selectedTitle' : ''}
        >
          <List>
            <ListItem button onClick={handleOnClick(title)}>
              <ListItemText primary={removeHyphen(title)} />
            </ListItem>
          </List>
        </div>
      ))}
    </div>
  );
}
