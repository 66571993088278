import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import logo from './media/motional-logo_horizontal_color_dark-theme.svg';
import { oktaLogout } from './okta';
import './Header.scss';

interface Props {
  toggleSidebar: () => void;
  isLargeWindow: boolean;
  className?: string;
}

export default function Header({
  toggleSidebar,
  className,
  isLargeWindow,
}: Props) {
  return (
    <AppBar position="static" className={`appBar ${className || ''}`}>
      <Toolbar>
        {!isLargeWindow && (
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={toggleSidebar}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
        )}
        <img className="logo" src={logo} alt="Motional" />
        <Typography variant="h6" noWrap>
          | API Documentations
        </Typography>
        <Button
          variant="outlined"
          className="login-button"
          size="small"
          onClick={oktaLogout}
        >
          Log out
        </Button>
      </Toolbar>
    </AppBar>
  );
}
