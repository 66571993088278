import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { useCallback, useState } from 'react';
import './SnackBarWrapper.scss';

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

interface Props {
  variant: keyof typeof variantIcon;
  message: string;
}

export default function SnackbarWrapper(props: Props) {
  const { variant, message } = props;
  const Icon = variantIcon[variant];

  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
    >
      <SnackbarContent
        className={variant}
        message={
          <span className={variant}>
            <Icon />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}
